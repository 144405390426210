@import "./color-variable.scss";
@import "./antd.scss";
@import "./theme.scss";
@import "~antd/dist/antd.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import url("https://fonts.googleapis.com/css?family=Kantumruy|Ubuntu&display=swap");

#root {
  height: 100%;
}

body {
  font-family: "Ubuntu", "Kantumruy" !important;
}

.content-center-screen {
  top: 50%;
  left: 50%;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
}

:export {
  primaryColor: $primary-color;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-danger {
  color: $error-color !important;
}

.text-info {
  color: #3fa7e1 !important;
}

.text-shadow {
  text-shadow: rgba(0, 0, 0, 0.25) 1px 1px 1px !important;
}

.border-primary {
  border-color: $primary-color !important;
}

.border-danger {
  border-color: $error-color !important;
}

.bg-gradient-primary-left-right {
  background-image: $gradient-primary-left-right !important;
}

.bg-gradient-primary-top-left-bottom-right {
  background-image: $gradient-primary-top-left-bottom-right !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-primary-fade {
  background-color: #ece8fd !important;
}

.bg-danger {
  background-color: #f7d7eb !important;
}

.box-shadow {
  box-shadow: $box-shadow-base !important;
}

@for $pixel from 1 through 5 {
  .border-#{$pixel} {
    border: #{$pixel}px solid;
  }
}

@for $pixel from 1 through 5 {
  .border-bottom-#{$pixel} {
    border-bottom: #{$pixel}px solid;
  }
}

@for $pixel from 1 through 25 {
  .border-radius-#{$pixel} {
    border-radius: #{$pixel}px !important;
  }
}

@for $pixel from 1 through 50 {
  .font-#{$pixel} {
    font-size: #{$pixel}px !important;
  }
}

@for $pixel from 1 through 500 {
  .width-#{$pixel} {
    width: #{$pixel}px;
  }
  .height-#{$pixel} {
    height: #{$pixel}px;
  }
}

@for $percent from 1 through 100 {
  @if $percent % 5 {
    .w-#{$percent} {
      width: #{$percent}% !important;
    }
    .h-#{$percent} {
      height: #{$percent}% !important;
    }
  }
}

/* 35.Loading */
.loading-overlay {
  z-index: 1050;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba($primary-color, 0.2);
  border-radius: 50%;
  border-top-color: $primary-color;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 999999999999999;
}

.navbar .custom-navbar-brand {
  margin: 0rem;
  padding: 0rem;
  display: flex;
  align-items: flex-end;
  position: relative;

  .custom-image-brand {
    position: relative;
    width: 200px !important;
  }
}

.custom-col {
  padding-bottom: 40px !important;

  h6 {
    margin-top: 10px;
  }

  .ant-checkbox-wrapper {
    margin-top: 10px;
  }

  .ant-btn {
    color: #fff !important;
    background-color: #db0d4c !important;
    text-align: center !important;
    padding: 12px !important;
  }
}
