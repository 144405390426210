.ant-input,
.ant-select .ant-select-selection__rendered {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;

  &.ant-select-selection__rendered {
    margin: 0rem;

    .ant-select-selection__placeholder {
      left: 1.5rem !important;
    }
  }
}

.ant-time-picker {
  .ant-time-picker-input {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  i,
  span.ant-time-picker-icon {
    display: block;
    right: 1.5rem !important;
  }
}

.ant-select,
.ant-calendar-picker {
  i,
  span.ant-select-arrow,
  span.ant-select-selection__clear {
    display: block;
    right: 1.5rem !important;
  }
}

.ant-form-item-with-help.ant-form-item {
  margin-bottom: 0.5rem !important;

  .ant-form-explain {
    text-align: left;
    white-space: nowrap;
    padding-top: 0.25rem;
  }
}

.ant-modal.ant-modal-danger {
  background: #f7d7eb !important;
  border: 1.5px solid $error-color !important;
  border-radius: 5px !important;
  padding-bottom: 0px !important;
}

.ant-modal-danger .ant-modal-content {
  background-color: transparent !important;
  border-radius: 0px !important;
}

.ant-btn.ant-btn-danger {
  color: $white-color !important;
  background-color: $error-color !important;
}

.ant-modal-close-icon {
  color: $error-color !important;
  font-size: 21px;
}

.ant-table-body {
  overflow-x: auto;
  padding: 0rem 0.25rem;
}

.ant-table-thead > tr > th {
  font-size: 16px;
  white-space: nowrap;
  font-weight: bold !important;
  border-bottom: unset !important;
  background-color: transparent !important;
}

.ant-input-affix-wrapper {
  &.border-0 input.ant-input {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  &.text-primary input.ant-input {
    color: #a96ab5;

    &::placeholder {
      color: #a96ab5;
    }
  }
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 4px !important;
}

.ant-menu-submenu.ant-menu-submenu-popup {
  .ant-menu {
    overflow: hidden !important;

    .ant-menu-item {
      margin: 0px !important;
    }
  }
}
